import { Page } from "@ludens-reklame/rubics-v3-sdk/page/types";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { CardGrid } from "../../internal-components/cardGrid/cardGrid.js";
import { CardVariant, PageCard } from "../../internal-components/card/card.js";
import { arrow } from "../../icons/svg.js";

interface PageEntry {
  page?: Page;
  colSpan?: "1" | "2" | "3";
  variant?: CardVariant;
}

interface Props {
  title?: string;
  variant?: CardVariant;
  link?: Link;
  pages?: PageEntry[];
}

const SelectedPages: React.FC<Props> = ({
  title,
  pages = [],
  link,
  variant,
}) => {
  const validPages = pages.filter((p) => p.page);

  if (validPages.length === 0) {
    return null;
  }

  return (
    <div className="selected-pages vs-xl hs mw">
      {title && <h2 className="h4">{title}</h2>}
      <CardGrid>
        {validPages.map((p, k) => (
          <PageCard
            key={p.page?._id || k}
            page={p.page!}
            titleType="h2"
            className={p.colSpan ? `span-${p.colSpan}` : ""}
            variant={variant || p.variant}
          />
        ))}
      </CardGrid>
      {link && (
        <div className="vs-xl h-center">
          <a className="button" href={link.url} aria-label={link.ariaLabel}>
            {link.title} {arrow}
          </a>
        </div>
      )}
    </div>
  );
};

export default SelectedPages;
